import { FC, ReactElement, UIEvent, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { QuickDetailsSection } from 'src/charts/QuickDetailsSection'
import { HotLinksSection } from 'src/charts/HotLinksSection'
import { CalendarSection } from 'src/charts/CalendarSection'
import { CalendarDetails } from 'src/charts/CalendarDetails'
import { MostMentionsSection } from 'src/charts/MostMentionsSection'
import { LiveNotificationsSection } from 'src/charts/LiveNotificationsSection'
import { LocationChartSection } from 'src/charts/LocationChartSection'
import { RadarChartComponent } from 'src/charts/RadarChartComponent'
import { PositivityChartSection } from 'src/charts/PositivityChartSection'
import { BASE_API_DATE_FORMAT } from 'src/constants/dateFormats'
import { getSessionIdFromLocalStorage } from 'src/localStorage/sessionId/getSessionIdFromLocalStorage/getSessionIdFromLocalStorage'
import { DailyTrendsSection } from 'src/charts/DailyTrendsSection'
import { useGetLocationsQuery } from 'src/reactQuery/useGetLocationsQuery'
import { INITIAL_ZOOM } from 'src/charts/LocationChartSection/constants/zoom'
import { adjustZoomToAPI } from 'src/reactQuery/useGetLocationsQuery/utils/adjustZoomToAPI'
import {
  AnalyticsFetchThreadsResponseDataItem,
  AnalyticsFetchThreadsSentimentType,
} from 'src/services/__generated__/api'
import {
  LIVE_NOTIFICATIONS_CONTAINER_HEIGHT,
  LIVE_NOTIFICATIONS_REFETCH_INTERVAL,
} from 'src/constants/vars'
import { useGetPositiveLiveNotificationsQuery } from 'src/reactQuery/useGetPositiveLiveNotificationsQuery'
import { useGetNegativeLiveNotificationsQuery } from 'src/reactQuery/useGetNegativeLiveNotificationsQuery'
import { useGetDailyTrendsQuery } from 'src/reactQuery/useGetDailyTrendsQuery'
import { useGetMostMentionsQuery } from 'src/reactQuery/useGetMostMentionsQuery'
import { useGetPositivityPercentQuery } from 'src/reactQuery/useGetPositivityPercentQuery'
import { useGetRadarChartQuery } from 'src/reactQuery/useGetRadarChartQuery'
import { useGetHotLinksQuery } from 'src/reactQuery/useGetHotLinksQuery'
import { useGetQuickDetailsQuery } from 'src/reactQuery/useGetQuickDetailsQuery'
import { useGetHomeCalendarQuery } from 'src/reactQuery/useGetHomeCalendarQuery'
import { useGetHomeCalendarDetailsQuery } from 'src/reactQuery/useGetHomeCalendarDetailsQuery'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { GlobalQueryParams } from 'src/interfaces/globalQueryParams.interface'
import { getEndOfToday } from 'src/utils/date'
import en from 'src/constants/en'
import { getDateRangeByQueryParams } from 'src/utils/getDateRangeByQueryParams'
import styles from './home.module.scss'

export const HomePage: FC = (): ReactElement => {
  const [locationZoom, setLocationZoom] = useState(INITIAL_ZOOM)

  const sessionId = getSessionIdFromLocalStorage()

  const { queryParams } = useQueryParams<GlobalQueryParams>()

  const dates = getDateRangeByQueryParams(queryParams)

  const startDate = dates?.start
  const endDate = dates?.end

  const getCalendarActiveDate = (
    startDate?: string,
    endDate?: string
  ): string => {
    if (!startDate || !endDate) {
      return moment().format(BASE_API_DATE_FORMAT)
    }

    if (moment(endDate).isSame(moment(), 'day')) {
      return endDate
    }

    return startDate
  }

  const [homeCalendarStartDate, setHomeCalendarStartDate] = useState<
    string | null
  >(startDate || null)

  const [homeCalendarEndDate, setHomeCalendarEndDate] = useState<string | null>(
    endDate || null
  )

  const [activeDate, setActiveDate] = useState<string>(
    getCalendarActiveDate(startDate, endDate)
  )

  const {
    data: locationsData,
    refetch: refetchLocations,
    isFetching: isLocationsFetching,
  } = useGetLocationsQuery({
    fromDate: startDate || undefined,
    toDate: endDate || undefined,
    zoom: adjustZoomToAPI(locationZoom),
  })

  const {
    data: dailyTrendsData,
    refetch: refetchDailyTrends,
    isFetching: isDailyTrendsFetching,
  } = useGetDailyTrendsQuery({
    end: endDate,
    start: startDate,
  })

  const {
    data: mostMentionsData,
    refetch: refetchMostMentions,
    isFetching: isMostMentionsFetching,
  } = useGetMostMentionsQuery({
    end: endDate,
    start: startDate,
  })

  const {
    data: positivityPercentData,
    refetch: refetchPositivityPercent,
    isFetching: isPositivityPercentFetching,
  } = useGetPositivityPercentQuery({
    end: endDate,
    start: startDate,
  })

  const {
    data: radarChartData,
    refetch: refetchRadarChart,
    isFetching: isRadarChartFetching,
  } = useGetRadarChartQuery({
    end: endDate,
    start: startDate,
  })

  const {
    data: hotLinksData,
    refetch: refetchHotLinks,
    isFetching: isHotLinksFetching,
  } = useGetHotLinksQuery({
    end: endDate,
    start: startDate,
  })

  const {
    data: quickDetailsData,
    refetch: refetchQuickDetails,
    isFetching: isQuickDetailsFetching,
  } = useGetQuickDetailsQuery({
    end: endDate,
    start: startDate,
  })

  const {
    data: homeCalendarData,
    refetch: refetchHomeCalendar,
    isFetching: isHomeCalendarFetching,
  } = useGetHomeCalendarQuery({
    end: homeCalendarEndDate,
    start: homeCalendarStartDate,
  })

  const {
    data: calendarDetailsData,
    refetch: refetchCalendarDetails,
    isFetching: isCalendarDetailsFetching,
  } = useGetHomeCalendarDetailsQuery({
    date: activeDate,
  })

  const [quickDetailsActiveItemIndex, setQuickDetailsActiveItemIndex] =
    useState<number>(0)

  const [mostMentionsActiveItemIndex, setMostMentionsActiveItemIndex] =
    useState<number>()

  const scrollQuickDetailsToIndex = useRef<(index: number) => void>()

  const updateQuickDetailsActiveItemIndex = (index: number): void => {
    setQuickDetailsActiveItemIndex(index)

    if (scrollQuickDetailsToIndex.current) {
      scrollQuickDetailsToIndex.current(index)
    }
  }

  const updateMostMentionsActiveItemIndex = (index?: number): void => {
    setMostMentionsActiveItemIndex(index)

    if (index !== undefined) {
      updateQuickDetailsActiveItemIndex(index)
    }
  }

  const setHomeCalendarDates = (newStartDate: string, newEndDate: string) => {
    if (newStartDate !== homeCalendarStartDate) {
      setHomeCalendarStartDate(newStartDate)
    }

    if (newEndDate !== homeCalendarEndDate) {
      setHomeCalendarEndDate(newEndDate)
    }

    if (newEndDate !== activeDate) {
      setActiveDate(getCalendarActiveDate(newStartDate, newEndDate))
    }
  }

  const navigateMonths = (range: string): void => {
    const date = moment(range)

    const newStartDate = date.startOf('month').format(BASE_API_DATE_FORMAT)

    const newEndDate = date.endOf('month').format(BASE_API_DATE_FORMAT)

    setHomeCalendarDates(newStartDate, newEndDate)

    setActiveDate(getCalendarActiveDate(newStartDate, newEndDate))
  }

  const navigateDays = (date: string): void => {
    setActiveDate(date)

    if (
      homeCalendarData?.range &&
      !moment(homeCalendarData.range).isSame(date, 'month')
    ) {
      const selectedDate = moment(date)

      const newStartDate = selectedDate
        .startOf('month')
        .format(BASE_API_DATE_FORMAT)

      const newEndDate = selectedDate
        .endOf('month')
        .format(BASE_API_DATE_FORMAT)

      setHomeCalendarDates(newStartDate, newEndDate)
    }
  }

  const [
    isPositiveNotificationsScrolledUp,
    setIsPositiveNotificationsScrolledUp,
  ] = useState<boolean>(true)

  const [
    isPositiveNotificationsScrolledDown,
    setIsPositiveNotificationsScrolledDown,
  ] = useState<boolean>(false)

  const [positiveNotificationsData, setPositiveNotificationsData] = useState<
    AnalyticsFetchThreadsResponseDataItem[] | null
  >(null)

  const [
    isNegativeNotificationsScrolledUp,
    setIsNegativeNotificationsScrolledUp,
  ] = useState<boolean>(true)

  const [
    isNegativeNotificationsScrolledDown,
    setIsNegativeNotificationsScrolledDown,
  ] = useState<boolean>(false)

  const [negativeNotificationsData, setNegativeNotificationsData] = useState<
    AnalyticsFetchThreadsResponseDataItem[] | null
  >(null)

  const {
    data: positiveNotificationsChartData,
    refetch: refetchPositiveNotifications,
    isFetching: isPositiveNotificationsFetching,
  } = useGetPositiveLiveNotificationsQuery({
    lastDate:
      isPositiveNotificationsScrolledDown && positiveNotificationsData
        ? positiveNotificationsData[0]?.published_at?.toString()
        : getEndOfToday(),
    limit: isPositiveNotificationsScrolledDown
      ? (positiveNotificationsData?.length || 20) + 10
      : positiveNotificationsData?.length || 20,
  })

  const {
    data: negativeNotificationsChartData,
    refetch: refetchNegativeNotifications,
    isFetching: isNegativeNotificationsFetching,
  } = useGetNegativeLiveNotificationsQuery({
    lastDate:
      isNegativeNotificationsScrolledDown && negativeNotificationsData
        ? negativeNotificationsData[0]?.published_at?.toString()
        : getEndOfToday(),
    limit: isNegativeNotificationsScrolledDown
      ? (negativeNotificationsData?.length || 20) + 10
      : negativeNotificationsData?.length || 20,
  })

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    if (positiveNotificationsChartData) {
      setPositiveNotificationsData(positiveNotificationsChartData)

      timer = setTimeout(() => {
        if (isPositiveNotificationsScrolledUp) {
          refetchPositiveNotifications()
        }
      }, LIVE_NOTIFICATIONS_REFETCH_INTERVAL)
    }

    return () => timer && clearTimeout(timer)
  }, [positiveNotificationsChartData])

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    if (negativeNotificationsChartData) {
      setNegativeNotificationsData(negativeNotificationsChartData)

      timer = setTimeout(() => {
        if (isNegativeNotificationsScrolledUp) {
          refetchNegativeNotifications()
        }
      }, LIVE_NOTIFICATIONS_REFETCH_INTERVAL)
    }

    return () => timer && clearTimeout(timer)
  }, [negativeNotificationsChartData])

  useEffect(() => {
    const shouldRefetch =
      isPositiveNotificationsScrolledUp || isPositiveNotificationsScrolledDown

    if (shouldRefetch && !isPositiveNotificationsFetching) {
      refetchPositiveNotifications()
    }
  }, [isPositiveNotificationsScrolledUp, isPositiveNotificationsScrolledDown])

  useEffect(() => {
    const shouldRefetch =
      isNegativeNotificationsScrolledUp || isNegativeNotificationsScrolledDown

    if (shouldRefetch && !isNegativeNotificationsFetching) {
      refetchNegativeNotifications()
    }
  }, [isNegativeNotificationsScrolledUp, isNegativeNotificationsScrolledDown])

  const handleNotificationsScroll = (
    event: UIEvent,
    sentiment: AnalyticsFetchThreadsSentimentType
  ) => {
    if (!event.currentTarget) {
      return
    }

    const { scrollTop, scrollHeight } = event.currentTarget

    const isScrolledCloseToTop = scrollTop < 40
    const isScrolledCloseToBottom =
      scrollTop > scrollHeight - LIVE_NOTIFICATIONS_CONTAINER_HEIGHT - 40

    switch (sentiment) {
      case 'POS':
        if (isScrolledCloseToTop !== isPositiveNotificationsScrolledUp) {
          setIsPositiveNotificationsScrolledUp(isScrolledCloseToTop)
        }

        if (isScrolledCloseToBottom !== isPositiveNotificationsScrolledDown) {
          setIsPositiveNotificationsScrolledDown(isScrolledCloseToBottom)
        }
        break

      case 'NEG':
        if (isScrolledCloseToTop !== isNegativeNotificationsScrolledUp) {
          setIsNegativeNotificationsScrolledUp(isScrolledCloseToTop)
        }

        if (isScrolledCloseToBottom !== isNegativeNotificationsScrolledDown) {
          setIsNegativeNotificationsScrolledDown(isScrolledCloseToBottom)
        }
        break

      default:
        break
    }
  }

  useEffect(() => {
    if (!sessionId || !startDate || !endDate) {
      return
    }

    refetchLocations()

    refetchDailyTrends()

    refetchMostMentions()

    refetchHotLinks()

    refetchPositivityPercent()

    refetchQuickDetails()

    refetchRadarChart()

    if (!positiveNotificationsChartData && !isPositiveNotificationsFetching) {
      refetchPositiveNotifications()
    }

    if (!negativeNotificationsChartData && !isNegativeNotificationsFetching) {
      refetchNegativeNotifications()
    }

    setHomeCalendarDates(startDate, endDate)
  }, [sessionId, startDate, endDate])

  useEffect(() => {
    refetchHomeCalendar()
  }, [homeCalendarStartDate, homeCalendarEndDate])

  useEffect(() => {
    refetchCalendarDetails()
  }, [activeDate])

  return (
    <div className={styles.container}>
      <DailyTrendsSection
        chartData={dailyTrendsData || undefined}
        isLoading={isDailyTrendsFetching}
      />

      <div className={styles.horizontalContainer}>
        <MostMentionsSection
          activeItemIndex={mostMentionsActiveItemIndex}
          chartData={mostMentionsData?.result}
          isLoading={isMostMentionsFetching}
          updateActiveItemIndex={updateMostMentionsActiveItemIndex}
        />

        <QuickDetailsSection
          activeItemIndex={quickDetailsActiveItemIndex}
          chartData={quickDetailsData || undefined}
          isLoading={isQuickDetailsFetching}
          setScrollQuickDetailsToIndexCallback={(callback) => {
            scrollQuickDetailsToIndex.current = callback
          }}
          updateActiveItemIndex={updateQuickDetailsActiveItemIndex}
        />
      </div>

      <div className={styles.horizontalContainer}>
        <PositivityChartSection
          isLoading={isPositivityPercentFetching}
          mentions={positivityPercentData?.mentions || 0}
          negativePerc={positivityPercentData?.negativePerc || 0}
          neutralPerc={positivityPercentData?.neutralPerc || 0}
          positivePerc={positivityPercentData?.positivePerc || 0}
        />

        <HotLinksSection
          chartData={hotLinksData || undefined}
          isLoading={isHotLinksFetching}
        />
      </div>

      <div className={styles.horizontalContainer}>
        <LocationChartSection
          chartData={locationsData}
          isLoading={isLocationsFetching}
          onZoomChange={(zoom) => setLocationZoom(zoom)}
          themeName={en.allTopics}
        />

        <RadarChartComponent
          chartData={radarChartData || undefined}
          isLoading={isRadarChartFetching}
        />
      </div>

      <div className={styles.horizontalContainer}>
        <CalendarSection
          activeDate={activeDate}
          chartData={homeCalendarData}
          isLoading={isHomeCalendarFetching}
          navigateMonths={navigateMonths}
          onDateClick={(date) => setActiveDate(date)}
        />

        {calendarDetailsData && (
          <CalendarDetails
            date={activeDate || undefined}
            isLoading={isCalendarDetailsFetching}
            navigateDays={navigateDays}
            selectedDateData={calendarDetailsData}
          />
        )}
      </div>

      <div className={styles.horizontalContainer}>
        <LiveNotificationsSection
          handleNotificationsScroll={handleNotificationsScroll}
          isLoading={
            isPositiveNotificationsFetching || isNegativeNotificationsFetching
          }
          negativeData={negativeNotificationsData}
          positiveData={positiveNotificationsData}
        />
      </div>
    </div>
  )
}
