import { FC, useState, useEffect, MouseEvent } from 'react'
import TagsInput from 'react-tagsinput'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { CAMPAIGNS_ROUTE } from 'src/constants/routes'
import en from 'src/constants/en'
import { COLORS } from 'src/constants/colors'
import { useUpdateRelevancy } from 'src/reactQuery/useUpdateRelevancy'
import {
  ENTER_KEY_CODE,
  SPACE_KEY_CODE,
  FB_SUGGESTIONS,
  FB_TARGET_ENDPOINT_VALIDATION_REGEX,
  IG_SUGGESTIONS,
  INPUT_PROPS,
  TARGETS,
  MAX_TAGS,
} from 'src/constants/campaigns'
import useCampaignNameInput from 'src/hooks/useCampaignNameInput'
import { CampaignInput } from 'src/components/CampaignInput'
import { getTimezoneOffsetInHours } from 'src/utils/date'
import { convertArrayToLowerCase } from 'src/utils/convertArrayToLowerCase'
import {
  CampaignPayload,
  CampaignPayloadKeyword,
} from 'src/services/__generated__/api'
import { useCustomLink } from 'src/hooks/useCustomLink'
import { useCreateCampaignMutation } from 'src/reactQuery/useCreateCampaignMutation'
import { BackNavigationButton } from 'src/components/BackNavigationButton'
import { Tag } from 'src/components/Tag'
import Button from 'src/components/Button'
import { AddKeywordInput } from 'src/components/AddKeywordInput'
import { cn } from 'src/utils/cn'
import KeywordFormCard from './KeywordFormCard'
import styles from './addNewCampaign.module.scss'
import TimezoneSelectInput from './TimezoneSelectInput'
import hexToRgbA from '../../utils/hexToRgba'
import { removeFromArray } from '../../utils/removeFromArray'

export const AddNewCampaign: FC = () => {
  const [timezoneOffset, setTimezoneOffset] = useState<number>(
    getTimezoneOffsetInHours()
  )

  const [timezoneName, setTimezoneName] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  const [addedTargetsArr, setAddedTargetsArr] = useState<string[]>([])

  const [currentKeywordInput, setCurrentKeywordInput] = useState<string>('')

  // TODO: uncomment after relevancy functionality is ready
  // const [currentRelevancyKeyword, setCurrentRelevancyKeyword] =
  //   useState<Keyword | null>(null)

  const [addedKeywordsArr, setAddedKeywordsArr] = useState<string[]>([])

  const [registeredKeywordsArr, setRegisteredKeywordsArr] = useState<
    CampaignPayloadKeyword[]
  >([])

  const [draft, setDraft] = useState(false)

  const navigate = useNavigate()

  const backURL = useCustomLink(CAMPAIGNS_ROUTE)

  const [fbTargetEndpoints, setFbTargetEndpoints] = useState<string[]>([])

  const [tags, setTags] = useState<string[]>([])

  const [igTargetEndpoints, setIgTargetEndpoints] = useState<string[]>([])

  const relevancyMutation = useUpdateRelevancy()

  const [campaignName, setCampaignName] = useCampaignNameInput('')

  const mutation = useCreateCampaignMutation({
    onSuccess: () => {
      navigate(backURL)
    },
  })

  const handleAddTags = (updatedTags: string[]) => {
    const isRemoving = updatedTags.length < tags.length

    if (isRemoving) {
      setTags(updatedTags)
      return
    }

    const latestTag = updatedTags.at(-1)?.trim() ?? ''
    const lowerCaseLatestTag = latestTag.toLowerCase()

    updatedTags.splice(-1, 1, latestTag)

    if (convertArrayToLowerCase(tags).includes(lowerCaseLatestTag)) {
      toast.error(en.TAG_ERROR_ALREADY_IN_USE)
      return
    }

    if (updatedTags.length > MAX_TAGS) {
      toast.error(en.TAG_ERROR_LIMIT)
      return
    }

    if (lowerCaseLatestTag && lowerCaseLatestTag.split(' ').length > 3) {
      toast.error(en.TAG_ERROR_TOO_MANY_WORDS)
      return
    }

    let isTagUsedAsKeyword = false
    let isTagUsedAsKeywordCategory = false
    let isTagUsedAsPositiveSentiment = false
    let isTagUsedAsNegativeSentiment = false

    registeredKeywordsArr.forEach(
      ({ categories, name, negative, positive }) => {
        if (name?.toLowerCase() === lowerCaseLatestTag) {
          isTagUsedAsKeyword = true
        }

        if (
          convertArrayToLowerCase(categories || []).includes(lowerCaseLatestTag)
        ) {
          isTagUsedAsKeywordCategory = true
        }

        if (
          convertArrayToLowerCase(negative || []).includes(lowerCaseLatestTag)
        ) {
          isTagUsedAsNegativeSentiment = true
        }

        if (
          convertArrayToLowerCase(positive || []).includes(lowerCaseLatestTag)
        ) {
          isTagUsedAsPositiveSentiment = true
        }
      }
    )

    if (isTagUsedAsKeyword) {
      toast.error(en.TAG_ERROR_ALREADY_USED_AS_KEYWORD)
      return
    }

    if (isTagUsedAsKeywordCategory) {
      toast.error(en.TAG_ERROR_ALREADY_USED_AS_KEYWORD_CATEGORY)
      return
    }

    if (isTagUsedAsNegativeSentiment) {
      toast.error(en.TAG_ERROR_ALREADY_USED_AS_NEGATIVE_SENTIMENT)
      return
    }

    if (isTagUsedAsPositiveSentiment) {
      toast.error(en.TAG_ERROR_ALREADY_USED_AS_POSITIVE_SENTIMENT)
      return
    }

    toast.success(en.TAG_SUCCESS_ADDED)
    setTags(updatedTags)
  }

  function addNewKeyword() {
    const keyword = currentKeywordInput

    const keywordArray = keyword
      .trim()
      .split(',')
      .map((item) => item.trim())

    const isAnyKeywordEmpty = keywordArray.some((item) => item.length === 0)

    if (addedKeywordsArr.includes(keyword)) {
      toast.error('Keyword you entered is already in use')

      return
    }

    if (isAnyKeywordEmpty) {
      toast.error('Keyword can not be empty')

      return
    }

    if (convertArrayToLowerCase(tags).includes(keyword.toLowerCase())) {
      toast.error(en.KEYWORD_ERROR_NOT_SAME_TAG)

      return
    }

    setAddedKeywordsArr([...addedKeywordsArr, ...keywordArray])

    setCurrentKeywordInput('')

    toast.success('Keyword is added successfully')
  }

  function addTargets(val?: string) {
    if (val !== undefined) {
      if (!addedTargetsArr.includes(val)) {
        setAddedTargetsArr([...addedTargetsArr, val])
        addedTargetsArr.push(val)
      } else {
        const arr = removeFromArray(addedTargetsArr, val)
        setAddedTargetsArr([...arr])
      }
    }
  }

  function handleChangeIgTargetEndpoints(tags: string[]): void {
    const tagsWithoutAtSymbols = tags.map((tag) => tag.replaceAll('@', ''))

    setIgTargetEndpoints(tagsWithoutAtSymbols)
  }

  function handleChangeKeywordSentimentsValue(val: CampaignPayloadKeyword) {
    let tempArr = [...registeredKeywordsArr]

    const index = tempArr.findIndex((item) => item.name === val.name)

    if (index === -1) {
      tempArr = [...tempArr, val]
    } else {
      tempArr = tempArr.filter((item, i) => i !== index)
      tempArr.push(val)
    }

    setRegisteredKeywordsArr(tempArr)

    // TODO: uncomment after relevancy functionality is ready
    // setCurrentRelevancyKeyword(val)

    relevancyMutation.mutate({
      keyword: val.name,
      negatives: val.negative,
      positives: val.positive,
    })
  }

  // TODO: uncomment after relevancy functionality is ready
  /*
  const handleRelevancySuggestionClick = (
    item: FetchRelevancyDataSuggestionItem,
    positivity: boolean
  ) => {
    if (!currentRelevancyKeyword) {
      return
    }

    const { from, to } = item
    const { negative, positive } = currentRelevancyKeyword

    if (positivity && positive) {
      const repeatsIndex = positive.findIndex((sentiment) => sentiment === to)
      const updatedIndex = positive.findIndex((sentiment) => sentiment === from)

      if (repeatsIndex === -1) {
        positive[updatedIndex] = to
      } else {
        positive.splice(updatedIndex, 1)
      }
    } else if (negative) {
      const repeatsIndex = negative.findIndex((sentiment) => sentiment === to)
      const updatedIndex = negative.findIndex((sentiment) => sentiment === from)

      if (repeatsIndex === -1) {
        negative[updatedIndex] = to
      } else {
        negative.splice(updatedIndex, 1)
      }
    }

    handleChangeKeywordSentimentsValue({
      ...currentRelevancyKeyword,
      negative,
      positive,
    })
  }
  */

  async function addNewCampaign() {
    if (addedTargetsArr.length === 0) {
      toast.error(en.EMPTY_TARGETS_ERROR)

      return
    }

    const payload: CampaignPayload = {
      from: moment().toISOString(),
      keywords: registeredKeywordsArr,
      name: campaignName,
      status: !draft ? 'active' : 'draft',
      tags,
      targets: addedTargetsArr,
      timezone: timezoneOffset,
      timezoneName,
      to: new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      ).toISOString(),
    }

    if (addedTargetsArr.includes('Facebook')) {
      payload.facebook_target_endpoints = fbTargetEndpoints
    }

    if (addedTargetsArr.includes('Instagram')) {
      payload.instagram_target_endpoints = igTargetEndpoints
    }

    mutation.mutate(payload)
  }

  const handleSaveCampaign = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    addNewCampaign()
  }

  const handleSaveAsDraft = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setDraft(true)
  }

  const handleDeleteKeyword = (keyword: string) => {
    const temp = removeFromArray(addedKeywordsArr, keyword)

    setAddedKeywordsArr(temp)

    const index = registeredKeywordsArr.findIndex(
      (item) => item.name === keyword
    )

    const tempRegistered = registeredKeywordsArr.slice()

    if (index !== -1) {
      tempRegistered.splice(index, 1)
    }

    setRegisteredKeywordsArr(tempRegistered)
  }

  const handleKeywordUpdate = (keyword: string, index: number): void => {
    setAddedKeywordsArr((prevKeywords) => {
      const temp = [...prevKeywords]

      temp[index] = keyword

      return temp
    })

    setRegisteredKeywordsArr((prevKeywords) => {
      const temp = [...prevKeywords]

      temp[index].name = keyword

      return temp
    })
  }

  const onSelectAllTargets = () => {
    setAddedTargetsArr(addedTargetsArr.length === TARGETS.length ? [] : TARGETS)
  }

  useEffect(() => {
    if (draft !== false) addNewCampaign()
  }, [draft])

  useEffect(() => {
    setDraft(false)
  }, [])

  return (
    <div className={styles.addNewCampaign}>
      <BackNavigationButton title={en.createNewCampaign} url={backURL} />

      <div className={styles.body}>
        <form>
          <div className={styles.formSection}>
            <div className={styles.sectionLabel}>{en.campaignName}</div>

            <CampaignInput
              onChange={setCampaignName}
              placeholder={en.enterNameOfCampaign}
              themeName={campaignName}
            />
          </div>

          <div
            className={classNames(styles.formSection, styles.timezoneSection)}
          >
            <div className={styles.sectionLabel}>{en.timezone}</div>

            <TimezoneSelectInput
              onChange={(offset, name) => {
                setTimezoneOffset(offset)
                setTimezoneName(name)
              }}
              timezoneName={timezoneName}
            />
          </div>

          <div className={styles.formSection}>
            <div
              className={cn(
                styles.sectionLabel,
                'flex items-center justify-between'
              )}
            >
              <span>{en.target}</span>

              <button
                className={cn('text-malibu', 'text-[14px]')}
                onClick={() => onSelectAllTargets()}
                type="button"
              >
                <span>
                  {addedTargetsArr.length === TARGETS.length
                    ? en.unSelectAll
                    : en.selectAll}
                </span>
              </button>
            </div>

            <div className={styles.targets}>
              {TARGETS.map((item) => (
                <Tag
                  backgroundColor={
                    addedTargetsArr.includes(item)
                      ? COLORS.malibu
                      : hexToRgbA(COLORS.periwinkle, 0.5)
                  }
                  color={
                    addedTargetsArr.includes(item) ? COLORS.white : COLORS.shark
                  }
                  cursor="pointer"
                  fontSize="14px"
                  fontWeight={500}
                  handleClick={(value) => addTargets(value)}
                  height="36px"
                  key={item}
                  lineHeight="16.41px"
                  padding="10px 16px"
                  title={item}
                />
              ))}
            </div>
          </div>

          <div
            className={classNames(
              styles.formSection,
              !addedTargetsArr.includes('Facebook') && styles.hidden
            )}
          >
            <div className={styles.sectionLabel}>
              {en.facebookTargetEndpoints}
            </div>

            <TagsInput
              addKeys={[ENTER_KEY_CODE, SPACE_KEY_CODE]}
              inputProps={INPUT_PROPS}
              onChange={(tags) => setFbTargetEndpoints(tags)}
              validationRegex={FB_TARGET_ENDPOINT_VALIDATION_REGEX}
              value={fbTargetEndpoints}
            />

            <div className={styles.suggestionWrapper}>
              <span className={styles.suggestionLabel}>{en.suggested}:</span>
              &nbsp;
              {FB_SUGGESTIONS.map((item) => {
                return (
                  <span className={styles.suggestionValue} key={item}>
                    {item}
                  </span>
                )
              })}
            </div>
          </div>

          <div
            className={classNames(
              styles.formSection,
              !addedTargetsArr.includes('Instagram') && styles.hidden
            )}
          >
            <div className={styles.sectionLabel}>
              {en.instagramTargetEndpoints}
            </div>

            <TagsInput
              addKeys={[ENTER_KEY_CODE, SPACE_KEY_CODE]}
              inputProps={INPUT_PROPS}
              onChange={(tags) => handleChangeIgTargetEndpoints(tags)}
              value={igTargetEndpoints}
            />

            <div className={styles.suggestionWrapper}>
              <span className={styles.suggestionLabel}>{en.suggested}:</span>
              &nbsp;
              {IG_SUGGESTIONS.map((item) => {
                return (
                  <span className={styles.suggestionValue} key={item}>
                    {item}
                  </span>
                )
              })}
            </div>
          </div>

          <div className={styles.formSection}>
            <div className={styles.sectionLabel}>{en.tags}</div>

            <TagsInput
              addKeys={[ENTER_KEY_CODE]}
              inputProps={INPUT_PROPS}
              onChange={(tags) => handleAddTags(tags)}
              value={tags}
            />
          </div>

          <div className={styles.formSection}>
            <AddKeywordInput
              addNewKeyword={() => addNewKeyword()}
              currentKeywordInput={currentKeywordInput}
              keywordCount={addedKeywordsArr.length}
              setCurrentKeywordInput={setCurrentKeywordInput}
            />
          </div>

          <div className={styles.formSection}>
            {addedKeywordsArr.map((item, index) => (
              <KeywordFormCard
                handleDelete={(keyword) => handleDeleteKeyword(keyword)}
                key={item}
                keyword={item}
                onChangeValue={(data) =>
                  handleChangeKeywordSentimentsValue(data)
                }
                onKeywordUpdate={(keyword) =>
                  handleKeywordUpdate(keyword, index)
                }
                reservedKeywords={tags}
              />
            ))}
          </div>

          <div className={styles.actionsWrapper}>
            <div className={styles.actionsWrapperInner}>
              <button
                className={styles.saveAsDraftButton}
                onClick={(e) => handleSaveAsDraft(e)}
                type="button"
              >
                {en.saveAsDraft}
              </button>

              <Button
                className={styles.addButton}
                onClick={(e) => handleSaveCampaign(e)}
                type="button"
              >
                {en.add}
              </Button>
            </div>
          </div>
        </form>

        <div className={styles.relevancy}>
          {/* TODO: uncomment after relevancy functionality is ready */}
          {/* 
          {!!relevancyMutation.data && (
            <Relevancy
              isLoading={relevancyMutation.isLoading}
              onClickSuggestionItem={handleRelevancySuggestionClick}
              relevancyData={relevancyMutation.data}
            />
          )}
          */}
        </div>
      </div>
    </div>
  )
}
