import { AnalyticsFetchSentimentAnalysisResponseData } from 'src/services/__generated__/api'
import { hasFields } from 'src/utils/hasFields'

export const isAnalyticsFetchSentimentAnalysisResponseData = (
  obj: unknown
): obj is AnalyticsFetchSentimentAnalysisResponseData => {
  return hasFields<AnalyticsFetchSentimentAnalysisResponseData>(
    obj,
    'allKeywords',
    'allSources'
  )
}
