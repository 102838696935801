import { FC, useMemo } from 'react'
import { EChartOption } from 'echarts'
import moment from 'moment'
import { COLORS } from 'src/constants/colors'
import { CheckBox } from 'src/components/CheckBox'
import en from 'src/constants/en'
import { useGetInformationTooltipsQuery } from 'src/reactQuery/useGetInformationTooltips'
import {
  KeywordFetchSentimentChart,
  AnalyticsFetchSentimentChartResponse,
} from 'src/services/__generated__/api'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { GlobalQueryParams } from 'src/interfaces/globalQueryParams.interface'
import { SectionTitle } from 'src/components/SectionTitle'
import { Tooltip } from 'src/components/Tooltip'
import { getDateRangeByQueryParams } from 'src/utils/getDateRangeByQueryParams'
import { Filter } from './Filter'
import styles from './sentimentChartSection.module.scss'
import { OnFilterSelectParams } from './interfaces/onFilterSelect.interface'
import { EChart } from '../EChart'
import { ChartNavigation } from '../ChartNavigation'

interface SentimentChartSectionProps {
  chartData?: AnalyticsFetchSentimentChartResponse | KeywordFetchSentimentChart
  isLoading?: boolean
  isNextYearDisabled?: boolean
  isPrevYearDisabled?: boolean
  onFilterSelect?: (filters: OnFilterSelectParams) => void
  onNextYearClick?: () => void
  onPrevYearClick?: () => void
  selectedYear?: number
}

const colorScheme = [
  COLORS.redOrange,
  COLORS.periwinkle,
  COLORS.pinkLace,
  COLORS.alto,
  COLORS.midGray,
  COLORS.burningOrange,
  COLORS.tide,
  COLORS.azureRadiance,
  COLORS.blizzardBlue,
  COLORS.malibu,
  COLORS.onahau,
  COLORS.turbo,
]

export const SentimentChartSection: FC<SentimentChartSectionProps> = ({
  chartData,
  isLoading,
  onFilterSelect,
  selectedYear,
  onNextYearClick,
  onPrevYearClick,
  isNextYearDisabled,
  isPrevYearDisabled,
}) => {
  const { queryParams } = useQueryParams<GlobalQueryParams>()

  const { data: informationTooltips } = useGetInformationTooltipsQuery()

  const options: EChartOption<EChartOption.SeriesScatter> | undefined =
    useMemo(() => {
      if (!chartData) {
        return undefined
      }

      const options: EChartOption<EChartOption.SeriesScatter> = {
        grid: {
          height: 294,
          left: 60,
          top: 10,
          width: 762,
        },
        legend: {
          bottom: 20,
          itemGap: 18,
          // @ts-ignore-next-line
          // We need to use this because of the bug in the library
          itemStyle: {
            marginRight: 8,
            opacity: 1,
          },
          itemWidth: 16,
          left: 50,
          selectedMode: false,
          show: true,
          textStyle: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 16.41,
          },
          width: 670,
        },
        series: chartData.data?.map((item, index) => ({
          data: item?.data,
          itemStyle: {
            borderColor: colorScheme[index],
            borderWidth: 1,
            color: colorScheme[index],
            opacity: 0.5,
          },
          name: item?.source,
          symbolSize: 36,
          type: 'scatter',
        })),
        tooltip: {
          show: true,
        },
        xAxis: {
          axisLabel: {
            formatter: (value: number) => {
              const dates = getDateRangeByQueryParams(queryParams)

              const differenceInDays =
                dates?.end && dates?.start
                  ? moment(dates.end).diff(moment(dates.start), 'days')
                  : 1

              if (differenceInDays === 0) {
                return moment(value).format('HH')
              }

              return moment(value).format('MMM DD')
            },
            margin: 20,
          },
          axisLine: {
            lineStyle: {
              color: COLORS.grey100,
            },
            onZero: false,
          },
          boundaryGap: false,
          interval: chartData?.XAxis?.interval,
          max: chartData?.XAxis?.max,
          min: chartData?.XAxis?.min,
          scale: true,
          splitLine: {
            show: true,
          },
        },
        yAxis: {
          axisLabel: {
            margin: 20,
          },
          axisLine: {
            lineStyle: {
              color: COLORS.grey100,
            },
          },
          boundaryGap: false,
          interval: chartData?.YAxis?.interval,
          max: chartData?.YAxis?.max,
          min: chartData?.YAxis?.min,
          scale: true,
          splitLine: {
            show: true,
          },
        },
      }

      return options
    }, [chartData])

  const toggleFilter = (
    key: string,
    activeKeys: string[],
    isActive: boolean
  ) => {
    if (isActive) {
      return activeKeys.filter((item) => item !== key)
    }

    return [...activeKeys, key]
  }

  const getIsActive = (key: string, activeKeys: string[]): boolean => {
    return !!activeKeys.map((key) => key)?.includes(key)
  }

  return (
    <div className={styles.horizontalContainer}>
      <div className={styles.chartWide}>
        <SectionTitle
          endItem={
            !!selectedYear && (
              <div className="ml-auto w-[850px] pr-[80px]">
                <ChartNavigation
                  isLeftDisabled={isPrevYearDisabled}
                  isRightDisabled={isNextYearDisabled}
                  onLeftClick={onPrevYearClick}
                  onRightClick={onNextYearClick}
                  title={selectedYear}
                />
              </div>
            )
          }
          isLoading={isLoading}
          title={en.sentimentChart}
          tooltipContent={informationTooltips?.sentimentChart}
        />

        <div className={styles.chartContainer}>
          <div className={styles.navMenu}>
            {!!chartData?.allSources?.length && (
              <Filter name="Source Type">
                <div className={styles.submenuInner}>
                  {chartData?.allSources?.map((source) => {
                    const isActive = !!getIsActive(
                      source,
                      chartData?.checkedSources || []
                    )

                    return (
                      <div
                        className={styles.submenuItem}
                        key={source}
                        onClick={() => {
                          if (onFilterSelect) {
                            onFilterSelect({
                              keywords: chartData?.checkedKeywords || [],
                              sources: toggleFilter(
                                source,
                                chartData?.checkedSources || [],
                                isActive
                              ),
                            })
                          }
                        }}
                      >
                        <CheckBox isChecked={isActive} />

                        <Tooltip
                          mouseEnterDelay={0.5}
                          overlay={source}
                          placement="top"
                        >
                          <span className={styles.submenuLabel} title={source}>
                            {source}
                          </span>
                        </Tooltip>
                      </div>
                    )
                  })}
                </div>
              </Filter>
            )}

            {chartData?.allKeywords?.length && (
              <Filter name="Keywords">
                <div className={styles.submenuInner}>
                  {chartData.allKeywords.map((keyword) => {
                    const isActive = !!getIsActive(
                      keyword,
                      chartData?.checkedKeywords || []
                    )

                    return (
                      <div
                        className={styles.submenuItem}
                        key={keyword}
                        onClick={() => {
                          if (onFilterSelect) {
                            onFilterSelect({
                              keywords: toggleFilter(
                                keyword,
                                chartData?.checkedKeywords || [],
                                isActive
                              ),
                              sources: chartData?.checkedSources || [],
                            })
                          }
                        }}
                      >
                        <CheckBox isChecked={isActive} />

                        <Tooltip
                          mouseEnterDelay={0.5}
                          overlay={keyword}
                          placement="top"
                        >
                          <span className={styles.submenuLabel}>{keyword}</span>
                        </Tooltip>
                      </div>
                    )
                  })}
                </div>
              </Filter>
            )}

            {chartData?.allCampaigns?.length && (
              <Filter name="Campaigns">
                <div className={styles.submenuInner}>
                  {chartData.allCampaigns.map((campaign) => {
                    const isActive = !!getIsActive(
                      campaign,
                      chartData.checkedCampaigns || []
                    )

                    return (
                      <div
                        className={styles.submenuItem}
                        key={campaign}
                        onClick={() => {
                          if (onFilterSelect) {
                            onFilterSelect({
                              campaigns: toggleFilter(
                                campaign,
                                chartData.checkedCampaigns || [],
                                isActive
                              ),
                              sources: chartData.checkedSources || [],
                            })
                          }
                        }}
                      >
                        <CheckBox isChecked={isActive} />

                        <Tooltip
                          mouseEnterDelay={0.5}
                          overlay={campaign}
                          placement="top"
                        >
                          <span className={styles.submenuLabel}>
                            {campaign}
                          </span>
                        </Tooltip>
                      </div>
                    )
                  })}
                </div>
              </Filter>
            )}
          </div>

          <div className={styles.chartWrapper}>
            {options && (
              <EChart<EChartOption<EChartOption.SeriesScatter>>
                options={options}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
