import { MAX_CAMPAIGN_NAME_LENGTH, MAX_TAGS } from './campaigns'
import { MINIMUM_PASSWORD_LENGTH } from './password'

export default {
  CAMPAIGN_ERROR_NAME_TOO_LONG: `Campaign name should not be more than ${MAX_CAMPAIGN_NAME_LENGTH} characters`,
  COMPLETE_CAMPAIGN_SUCCESS_MESSAGE: 'Campaign completed successfully',
  CREATE_CAMPAIGN_ERROR_MESSAGE: 'Failed to create campaign',
  CREATE_CAMPAIGN_SUCCESS_MESSAGE: 'Campaign created successfully',
  EMAIL_EXAMPLE: 'mail@website.com',
  EMPTY_TARGETS_ERROR: 'Target source is not identified',
  ERROR_CSV: 'Error downloading CSV',
  FACEBOOK_LOGIN_ERROR_MESSAGE: 'Failed to login with Meta',
  FACEBOOK_LOGOUT_ERROR_MESSAGE: 'Failed to logout from Meta',
  HEADER_DATEPICKER_TOOLTIP:
    'The date range you select will be applied to all reports.',
  INVALID_CREDENTIALS: 'Invalid credentials',
  KEYWORD_ERROR_NOT_SAME_TAG: 'Keyword can not be the same as tag',
  KEYWORD_ERROR_OVER_10_WORDS: 'Tag should not exceed 10 words',
  MIN_CHARACTERS_PASSWORD: `Min. ${MINIMUM_PASSWORD_LENGTH} characters`,
  PASSWORD_ERROR_NOT_FILLED_ALL_FIELDS:
    'Fill all password fields to update password',
  PASSWORD_ERROR_NOT_MATCHING: "New passwords don't match",
  PASSWORD_ERROR_TOO_SHORT: `New password must be at least ${MINIMUM_PASSWORD_LENGTH} characters long`,
  SAVE_CAMPAIGN_AS_DRAFT_SUCCESS_MESSAGE:
    'Campaign saved as draft successfully',
  SENTIMENT_ERROR_ALREADY_IN_USE: 'Sentiment you entered is already in use',
  SENTIMENT_ERROR_ALREADY_USED_AS_NEGATIVE:
    'Sentiment you entered is already used as negative sentiment',
  SENTIMENT_ERROR_ALREADY_USED_AS_POSITIVE:
    'Sentiment you entered is already used as positive sentiment',
  SENTIMENT_ERROR_NOTHING_TO_PASTE: 'Nothing to paste',
  SENTIMENT_ERROR_NO_UNIQUE_SENTIMENTS_PASTED:
    'No unique sentiments were detected',
  SENTIMENT_ERROR_OVER_20_WORDS:
    'Sentiments identified in keyword should not be more than 20 words',
  SENTIMENT_ERROR_PASTE_NEGATIVE: 'Cant paste negative sentiment(s): ',
  SENTIMENT_ERROR_PASTE_POSITIVE: 'Cant paste positive sentiment(s): ',
  SENTIMENT_ERROR_TOO_MANY_WORDS: 'Sentiment should not exceed 3 words',
  SENTIMENT_ERROR_USED_AS_KEYWORD: 'Sentiment you entered is used as keyword',
  SENTIMENT_ERROR_USED_WRONG_CHARACTERS:
    'You cannot use characters in sentiments except " - "',
  SENTIMENT_SUCCESS_PASTED: 'Sentiments were pasted successfully',
  TAG_ERROR_ALREADY_IN_USE: 'Tag you entered is already in use',
  TAG_ERROR_ALREADY_USED_AS_KEYWORD: 'Tag you entered is used as keyword',
  TAG_ERROR_ALREADY_USED_AS_KEYWORD_CATEGORY:
    'Tag you entered is keyword category',
  TAG_ERROR_ALREADY_USED_AS_NEGATIVE_SENTIMENT:
    'Tag you entered is already used as negative sentiment',
  TAG_ERROR_ALREADY_USED_AS_POSITIVE_SENTIMENT:
    'Tag you entered is already used as positive sentiment',
  TAG_ERROR_LIMIT: `Tags identified in campaign should not be more than ${MAX_TAGS}`,
  TAG_ERROR_TOO_MANY_WORDS: 'Tag should not exceed 3 words',
  TAG_SUCCESS_ADDED: 'Tag added successfully',
  UPDATE_CAMPAIGN_SUCCESS_MESSAGE: 'Campaign updated successfully',
  USER_PASSWORD_UPDATE_ERROR_MESSAGE: 'Failed to update password',
  USER_PASSWORD_UPDATE_SUCCESS_MESSAGE: 'Password updated successfully',
  USER_UPDATE_ERROR_MESSAGE: 'Failed to update user',
  USER_UPDATE_SUCCESS_MESSAGE: 'User updated successfully',
  WORD_IS_ALREADY_IN_USE: 'Word is already in use',
  add: 'Add',
  addNewCampaign: 'Add new Campaign',
  addNewCategory: 'Add new Category',
  addNewKeyword: 'Add new Keyword',
  all: 'All',
  allTopics: 'All Topics',
  areYouSure: 'Are you sure',
  areYouSureYouWantToDelete: 'Are you sure you want to delete',
  calendar: 'Calendar',
  calendarDetails: 'Calendar Details',
  campaign: 'Campaign',
  campaignName: 'Campaign name',
  campaigns: 'Campaigns',
  cancel: 'Cancel',
  categories: 'Categories',
  category: 'Category',
  categoryName: 'Category name',
  chooseCampaign: 'Choose Campaign',
  company: 'Company',
  companyName: 'Company name',
  complete: 'Complete',
  confirm: 'Confirm',
  confirmPassword: 'Confirm password',
  connect: 'Connect',
  countries: 'Countries',
  country: 'Country',
  create: 'Create',
  createAccount: 'Create Account',
  createNewCampaign: 'Create New Campaign',
  createNewKeyword: 'Create New Keyword',
  createdAt: 'Created at',
  custom: 'Custom',
  dailyNegativeMentions: 'Daily Negative Mentions',
  dailyPositiveCampaign: 'Daily Positive Campaign',
  dailyPositiveMentions: 'Daily Positive Mentions',
  dailyTrends: 'Daily Trends',
  delete: 'Delete',
  disconnect: 'Disconnect',
  downloadCsvReport: 'Download CSV Report',
  downoadCSV: 'Download CSV',
  edit: 'Edit',
  email: 'Email',
  enterAKeyword: 'Enter a Keyword',
  enterNameOfCampaign: 'Enter name of campaign',
  facebook: 'Facebook',
  facebookTargetEndpoints: 'Facebook target page addresses',
  filter: 'Filter',
  filters: 'Filters',
  firstName: 'First name',
  forgotPassword: 'Forgot password?',
  gender: 'Gender',
  haveAccount: 'Already have an account?',
  hotLinks: 'Hot Links',
  instagramTargetEndpoints: 'Instagram target page names',
  keyWordsAdded: 'Keywords added',
  keyword: 'Keyword',
  keywordAnalyses: 'Keyword Analyses',
  keywords: 'Keywords',
  last30Days: 'Last 30 days',
  last7Days: 'Last 7 days',
  last90Days: 'Last 90 days',
  lastName: 'Last name',
  less: 'Less',
  lifetime: 'Lifetime',
  liveNotifications: 'Live Notifications',
  loading: 'Loading ...',
  locationChart: 'Location Chart',
  locationChartAdditionalText:
    'Location Chart collects data that should be incomplete.',
  locationChartUsingList: 'Location Chart using List',
  locationChartUsingMap: 'Location Chart using Map',
  logOut: 'Log out',
  login: 'Log in',
  mentions: 'Mentions',
  mentionsByMonth: 'Mentions by Month',
  meta: 'Meta',
  min8Characters: 'Min. 8 characters',
  more: 'More',
  mostMentions: 'Most Mentions',
  mostMentionsAmount: 'Most Mentions Amount',
  mostlyNegative: 'Mostly Negative',
  mostlyNeutral: 'Mostly Neutral',
  mostlyPositive: 'Mostly Positive',
  mostlyRatedAs: 'Mostly Rated As',
  name: 'Name',
  negative: 'Negative',
  negativeMentions: 'Negative mentions',
  negativeSentiments: 'Negative sentiments',
  negatives: 'Negatives',
  neutral: 'Neutral',
  neutralMentions: 'Neutral mentions',
  neutrals: 'Neutrals',
  newPassword: 'New password',
  next: 'Next',
  noCampaignsFound: 'No campaigns found',
  noKeywordsFound: 'No keywords found',
  noPostsAvailable: 'No posts available',
  notEnoughDataToDisplay: 'There is not enough data to display',
  nothingHereYet: 'Nothing here yet',
  number: 'Number',
  ofTotalMentions: 'Of Total Mentions',
  oldPassword: 'Old password',
  orderBy: 'Order By',
  others: 'Others',
  password: 'Password',
  positive: 'Positive',
  positiveMentions: 'Positive mentions',
  positiveSentiments: 'Positive sentiments',
  positives: 'Positives',
  positivityChart: 'Positivity Chart',
  quickDetails: 'Quick Details',
  radarChart: 'Radar chart',
  region: 'Region',
  relevancy: 'Relevancy',
  relevancyTitleTooltip:
    'Using more general keywords will help you catch more sentiments.',
  rememberMe: 'Remember me',
  remove: 'Remove',
  removeKeyword: 'Remove Keyword',
  rename: 'Rename',
  resetFilter: 'Reset Filter',
  save: 'Save',
  saveAsDraft: 'Save to draft',
  saveAsTemplate: 'Save as template',
  search: 'Search',
  seeAll: 'See all',
  seeOriginal: 'See original',
  seeTranslation: 'See translation',
  selectAll: 'Select all',
  selectDeselectAllCampaigns: 'Select/Deselect All Campaigns',
  selected: 'Selected',
  sentimentAnalyses: 'Sentiment Analyses',
  sentimentChart: 'Sentiment chart',
  sentiments: 'Sentiments',
  settings: 'Settings',
  signInToAccessYourAccount: 'Sign in to access your account',
  signup: 'Sign up',
  somethingWentWrong: 'Something went wrong',
  sources: 'Sources',
  suggested: 'Suggested',
  suggestions: 'Suggestions',
  tagTooltip: 'You cannot edit target sources',
  tags: 'Tags',
  target: 'Target',
  textIsTranslatedFrom: 'This text is translated from',
  than: 'Than',
  themeName: 'Campaign name',
  themesSelected: 'Themes selected',
  threads: 'Threads',
  timezone: 'Timezone',
  today: 'Today',
  topCampaigns: 'Top Campaigns',
  topKeywords: 'Top Keywords',
  topLocation: 'Top Location',
  topSource: 'Top Source',
  topSources: 'Top Sources',
  total: 'Total',
  totalMentions: 'Total mentions',
  unSelectAll: 'Unselect all',
  updateCampaign: 'Update Campaign',
  updatedAt: 'Updated at',
  worldwide: 'Worldwide',
  yesterday: 'Yesterday',
}
