import { FC, useEffect, useState, UIEvent } from 'react'
import classNames from 'classnames'
import en from 'src/constants/en'
import { LiveNotificationItem } from 'src/components/LiveNotificationItem'
import { Type } from 'src/components/LiveNotificationItem/enums/type.enum'
import {
  AnalyticsFetchThreadsResponseDataItem,
  AnalyticsFetchThreadsSentimentType,
} from 'src/services/__generated__/api'
import CardFilterButton from 'src/components/CardFilterButton'
import styles from './sentimentAnalysesNotifications.module.scss'
import liveNotificationsSectionStyles from '../../LiveNotificationsSection/liveNotifications.module.scss'

interface SentimentAnalysesNotificationsProps {
  allThreadsData?: AnalyticsFetchThreadsResponseDataItem[]
  handleThreadsScroll?: (
    event: UIEvent,
    sentiment?: AnalyticsFetchThreadsSentimentType
  ) => void
  hasFilters?: boolean
  negativeThreadsData?: AnalyticsFetchThreadsResponseDataItem[]
  neutralThreadsData?: AnalyticsFetchThreadsResponseDataItem[]
  onFilterClick?: () => void
  positiveThreadsData?: AnalyticsFetchThreadsResponseDataItem[]
}

export const SentimentAnalysesNotifications: FC<
  SentimentAnalysesNotificationsProps
> = ({
  allThreadsData,
  negativeThreadsData,
  neutralThreadsData,
  positiveThreadsData,
  handleThreadsScroll,
  onFilterClick,
  hasFilters,
}) => {
  const [filter, setFilter] = useState<string>(en.all)

  const [threadsData, setThreadsData] = useState<
    AnalyticsFetchThreadsResponseDataItem[]
  >([])

  const filterItems = [en.all, en.positive, en.negative, en.neutral]

  useEffect(() => {
    switch (filter) {
      case en.all:
        setThreadsData(allThreadsData || [])
        break
      case en.positive:
        setThreadsData(positiveThreadsData || [])
        break
      case en.negative:
        setThreadsData(negativeThreadsData || [])
        break
      case en.neutral:
        setThreadsData(neutralThreadsData || [])
        break
      default:
        setThreadsData(allThreadsData || [])
    }
  }, [
    filter,
    allThreadsData,
    negativeThreadsData,
    neutralThreadsData,
    positiveThreadsData,
  ])

  const getSentimentByFilter = ():
    | AnalyticsFetchThreadsSentimentType
    | undefined => {
    const filterToSentimentMap: {
      [key: string]: AnalyticsFetchThreadsSentimentType
    } = {
      [en.positive]: AnalyticsFetchThreadsSentimentType.POS,
      [en.negative]: AnalyticsFetchThreadsSentimentType.NEG,
      [en.neutral]: AnalyticsFetchThreadsSentimentType.NEU,
    }

    return filterToSentimentMap[filter]
  }

  const getTypeFromThread = (
    item: AnalyticsFetchThreadsResponseDataItem
  ): Type => {
    return (
      {
        [AnalyticsFetchThreadsSentimentType.POS]: Type.POSITIVE,
        [AnalyticsFetchThreadsSentimentType.NEG]: Type.NEGATIVE,
      }[item.output ?? ''] ?? Type.OTHER
    )
  }

  const getSentimentsFromThread = (
    item: AnalyticsFetchThreadsResponseDataItem
  ): string[] | undefined => {
    return {
      [AnalyticsFetchThreadsSentimentType.POS]: item.positive_keywords,
      [AnalyticsFetchThreadsSentimentType.NEG]: item.negative_keywords,
    }[item.output ?? '']
  }

  return (
    <div className={styles.sentimentAnalysesNotifications}>
      <div className={styles.chartInner}>
        <div className={styles.filtersWrapper}>
          {filterItems.map((item) => {
            const isActive = filter === item

            return (
              <div
                className={classNames(
                  styles.filterItem,
                  isActive && styles.filterItemActive
                )}
                key={item}
                onClick={() => setFilter(item)}
              >
                {item}
              </div>
            )
          })}
          {hasFilters && (
            <CardFilterButton
              className={styles.filterButton}
              onClick={() => onFilterClick?.()}
            />
          )}
        </div>

        <div
          className={liveNotificationsSectionStyles.scrollbarContainer}
          onScroll={(e: UIEvent) => {
            handleThreadsScroll?.(e, getSentimentByFilter())
          }}
        >
          {threadsData.length ? (
            threadsData.map((item) => (
              <LiveNotificationItem
                campaign={item.campaign_name}
                key={`${item._id} - ${filter}`}
                keyword={item.keyword}
                language={item.language}
                link={item.link}
                originalLangCode={item.original_lang_code}
                originalText={item.text}
                score={item.score}
                sentiments={getSentimentsFromThread(item)}
                source={item.source}
                time={item.published_at}
                translatedText={item.translated}
                type={getTypeFromThread(item)}
              />
            ))
          ) : (
            <div className={styles.emptyState}>
              <div>{en.noPostsAvailable}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
