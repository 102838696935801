import { FC, UIEvent } from 'react'
import en from 'src/constants/en'
import { LiveNotificationItem } from 'src/components/LiveNotificationItem'
import { Type } from 'src/components/LiveNotificationItem/enums/type.enum'
import { useGetInformationTooltipsQuery } from 'src/reactQuery/useGetInformationTooltips'
import {
  AnalyticsFetchThreadsResponseDataItem,
  AnalyticsFetchThreadsSentimentType,
} from 'src/services/__generated__/api'
import { SectionTitle } from 'src/components/SectionTitle'
import styles from './liveNotifications.module.scss'

interface LiveNotificationsSectionProps {
  handleNotificationsScroll: (
    event: UIEvent,
    sentiment: AnalyticsFetchThreadsSentimentType
  ) => void
  isLoading?: boolean
  negativeData: AnalyticsFetchThreadsResponseDataItem[] | null
  positiveData: AnalyticsFetchThreadsResponseDataItem[] | null
}

export const LiveNotificationsSection: FC<LiveNotificationsSectionProps> = ({
  handleNotificationsScroll,
  isLoading,
  negativeData,
  positiveData,
}) => {
  const { data: informationTooltips } = useGetInformationTooltipsQuery()

  return (
    <div className={styles.liveNotifications}>
      <SectionTitle
        isLoading={isLoading}
        liveIcon
        title={en.liveNotifications}
        tooltipContent={informationTooltips?.liveNotifications}
      />

      <div className={styles.innerContainer}>
        <div className={styles.notificationsContainer}>
          <div className={styles.notificationsContainerTitleWrapper}>
            <span className={styles.notificationsContainerTitle}>
              {en.positive}
            </span>
          </div>

          <div
            className={styles.scrollbarContainer}
            onScroll={(e) =>
              handleNotificationsScroll(
                e,
                AnalyticsFetchThreadsSentimentType.POS
              )
            }
          >
            {positiveData?.map((item) => (
              <LiveNotificationItem
                campaign={item.campaign_name}
                key={`${item.text} -${item.campaign_name} -${item.keyword} -${item.published_at}`}
                keyword={item.keyword}
                language={item.language}
                link={item.link}
                originalLangCode={item.original_lang_code}
                originalText={item.text}
                score={item.score}
                sentiments={item.positive_keywords}
                source={item.source}
                time={item.published_at}
                translatedText={item.translated}
                type={Type.POSITIVE}
              />
            ))}
          </div>
        </div>

        <div className={styles.notificationsContainer}>
          <div className={styles.notificationsContainerTitleWrapper}>
            <span className={styles.notificationsContainerTitle}>
              {en.negative}
            </span>
          </div>

          <div
            className={styles.scrollbarContainer}
            onScroll={(e) =>
              handleNotificationsScroll(
                e,
                AnalyticsFetchThreadsSentimentType.NEG
              )
            }
          >
            {negativeData?.map((item) => (
              <LiveNotificationItem
                campaign={item.campaign_name}
                key={`${item._id}`}
                keyword={item.keyword}
                language={item.language}
                link={item.link}
                originalLangCode={item.original_lang_code}
                originalText={item.text}
                score={item.score}
                sentiments={item.negative_keywords}
                source={item.source}
                time={item.published_at}
                translatedText={item.translated}
                type={Type.NEGATIVE}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
