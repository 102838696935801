import React, { FC } from 'react'
import { useMatch } from 'react-router-dom'
import {
  HOME_ROUTE,
  CAMPAIGNS_ROUTE,
  CAMPAIGN_DETAILS_ROUTE,
  KEYWORDS_ROUTE,
  KEYWORD_DETAILS_ROUTE,
} from 'src/constants/routes'
import styles from './topBar.module.scss'
import { TopBarDatePicker } from './TopBarDatePicker'
import { UserDropDown } from './UserDropDown'

export const TopBar: FC = () => {
  const isHomeRoute = useMatch({
    end: true,
    path: HOME_ROUTE,
  })

  const isCampaignRoute = useMatch({
    end: true,
    path: CAMPAIGNS_ROUTE,
  })

  const isCampaignDetailRoute = useMatch({
    end: true,
    path: CAMPAIGN_DETAILS_ROUTE,
  })

  const isKeywordRoute = useMatch({
    end: true,
    path: KEYWORDS_ROUTE,
  })

  const isKeywordDetailRoute = useMatch({
    end: true,
    path: KEYWORD_DETAILS_ROUTE,
  })

  const isDatePickerShown =
    isHomeRoute ||
    isCampaignRoute ||
    isCampaignDetailRoute ||
    isKeywordRoute ||
    isKeywordDetailRoute

  return (
    <div className={styles.topBar}>
      {isDatePickerShown && <TopBarDatePicker />}

      <div className={styles.right}>
        {/* Temporarily hidden */}
        {/* <span>
          <img alt="" src={NotificationIcon} />
        </span> */}

        <UserDropDown />
      </div>
    </div>
  )
}
