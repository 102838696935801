import { FC } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { LOGIN_ROUTE } from 'src/constants/routes'
import { useGetSelfQuery } from 'src/reactQuery/useGetSelfQuery'
import { Layout } from '../Layout'

interface PrivateRouteProps {
  requiresAdminAccess?: boolean
}

export const PrivateRoute: FC<PrivateRouteProps> = ({
  requiresAdminAccess = false,
}) => {
  const { data: userData } = useGetSelfQuery()

  const isAuthenticated = requiresAdminAccess
    ? userData?.user?.role === 'admin'
    : userData?.user !== null

  return (
    <>
      {isAuthenticated ? (
        <Layout>
          <Outlet />
        </Layout>
      ) : (
        <Navigate to={LOGIN_ROUTE} />
      )}
    </>
  )
}
