import React, { FC, useEffect, useState } from 'react'
import en from 'src/constants/en'
import { FilterModalContentWrapper } from 'src/containers/FilterModalContentWrapper'
import { FilterSearchDropdownSection } from 'src/containers/FilterSearchDropdownSection'
import {
  AnalyticsFetchSentimentAnalysisResponseDataKeywordItem,
  AnalyticsFetchSentimentAnalysisResponseDataSourceItem,
} from 'src/services/__generated__/api'
import { MentionItem } from 'src/components/MentionItem'
import { FilterDropdownSelectOption } from 'src/containers/FilterSearchDropdown/interfaces/filterDropdownSelectOption.interface'
import styles from './filterModalContent.module.scss'

export interface FilterModalContentProps {
  allKeywords?: AnalyticsFetchSentimentAnalysisResponseDataKeywordItem[]
  allSources?: AnalyticsFetchSentimentAnalysisResponseDataSourceItem[]
  onClose: () => void
  onSave: (params: {
    selectedKeywords: string[]
    selectedSources: string[]
  }) => void
  selectedKeywords?: string[]
  selectedSources?: string[]
}

export const FilterModalContent: FC<FilterModalContentProps> = ({
  onSave,
  onClose,
  allKeywords,
  allSources,
  selectedKeywords,
  selectedSources,
}) => {
  const keywordsToOption = (
    keywords?: AnalyticsFetchSentimentAnalysisResponseDataKeywordItem[]
  ) => {
    return (
      keywords?.map((keywordItem) => ({
        label: keywordItem.keyword || '',
        labelAddition: keywordItem.total && (
          <MentionItem totalMentions={keywordItem.total} />
        ),
        value: keywordItem.keyword || '',
      })) || []
    )
  }

  const sourcesToOption = (
    sources?: AnalyticsFetchSentimentAnalysisResponseDataSourceItem[]
  ) => {
    return (
      sources?.map((sourceItem) => ({
        label: sourceItem.source || '',
        labelAddition: sourceItem.total && (
          <MentionItem totalMentions={sourceItem.total} />
        ),
        value: sourceItem.source || '',
      })) || []
    )
  }

  const optionToValue = (options?: FilterDropdownSelectOption[]) => {
    return options?.map((option) => option.value) || []
  }

  const mappedSelectedKeywords = keywordsToOption(
    allKeywords?.filter(
      (keywordItem) =>
        keywordItem.keyword && selectedKeywords?.includes(keywordItem.keyword)
    ) || []
  )

  const mappedSelectedSources = sourcesToOption(
    allSources?.filter(
      (sourceItem) =>
        sourceItem.source && selectedSources?.includes(sourceItem.source)
    ) || []
  )

  const [selectedKeywordsOptions, setSelectedKeywordsOptions] = useState<
    FilterDropdownSelectOption[]
  >(mappedSelectedKeywords)

  const [selectedSourcesOptions, setSelectedSourcesOptions] = useState<
    FilterDropdownSelectOption[]
  >(mappedSelectedSources)

  const keywordOptions = keywordsToOption(allKeywords)
  const sourceOptions = sourcesToOption(allSources)

  const handleReset = () => {
    setSelectedKeywordsOptions(keywordOptions)
    setSelectedSourcesOptions(sourceOptions)
  }

  useEffect(() => {
    setSelectedKeywordsOptions(mappedSelectedKeywords)
  }, [selectedKeywords?.toString()])

  useEffect(() => {
    setSelectedSourcesOptions(mappedSelectedSources)
  }, [selectedSources?.toString()])

  return (
    <FilterModalContentWrapper
      onClose={() => onClose()}
      onReset={() => handleReset()}
      onSave={() =>
        onSave({
          selectedKeywords: optionToValue(selectedKeywordsOptions),
          selectedSources: optionToValue(selectedSourcesOptions),
        })
      }
    >
      <div className={styles.filterModalContent}>
        <FilterSearchDropdownSection
          countLabel={en.sources}
          isOpen
          onSelect={setSelectedSourcesOptions}
          options={sourceOptions}
          placeholder={en.sources}
          selectedOptions={selectedSourcesOptions}
        />

        <FilterSearchDropdownSection
          countLabel={en.keywords}
          onSelect={setSelectedKeywordsOptions}
          options={keywordOptions}
          placeholder={en.keywords}
          selectedOptions={selectedKeywordsOptions}
        />
      </div>
    </FilterModalContentWrapper>
  )
}
