import moment from 'moment'
import { FC, ReactNode, ReactElement, useEffect, useState } from 'react'
import { SOCIAL_ICONS } from 'src/constants/socialIcons'
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter'
import { ReactComponent as ExternalLinkSvg } from 'src/assets/svg/external-link.svg'
import { getThreadExpandedText } from 'src/utils/getThreadExpandedText'
import { getThreadPreviewText } from 'src/utils/getThreadPreviewText'
import { DEFAULT_ORIGINAL_LANG_CODE } from 'src/constants/defaultOriginalLangCode'
import en from 'src/constants/en'
import { Type } from './enums/type.enum'
import styles from './liveNotificationItem.module.scss'
import { ClampedParagraph } from '../ClampedParagraph'
import HighlightedText from '../HighlightedText'

interface LiveNotificationItemProps {
  campaign?: string
  keyword?: string
  language?: string
  link?: string
  originalLangCode?: string
  originalText?: string
  score?: number
  sentiments?: string[]
  source?: string
  time?: string
  translatedText?: string
  type?: Type
}

export const LiveNotificationItem: FC<LiveNotificationItemProps> = ({
  link,
  source,
  time,
  campaign,
  originalText,
  keyword,
  sentiments = [],
  type = Type.OTHER,
  language,
  originalLangCode,
  translatedText,
  score,
}) => {
  const icon: ReactNode = source ? SOCIAL_ICONS[source.toLowerCase()] : null

  const [isTranslated, setIsTranslated] = useState(false)

  const [isExpanded, setIsExpanded] = useState(false)

  const [previewText, setPreviewText] = useState('')

  const [highlightedPreviewElement, setHighlightedPreviewElement] =
    useState<ReactElement | null>(null)

  const [highlightedExpandedElement, setHighlightedExpandedElement] =
    useState<ReactElement | null>(null)

  const text = isTranslated ? translatedText : originalText

  const getHighlightedText = (
    textToHighlight?: string
  ): ReactElement | null => (
    <HighlightedText
      keyword={keyword}
      sentiments={sentiments}
      textToHighlight={textToHighlight}
      type={type}
    />
  )

  useEffect(() => {
    const previewText = getThreadPreviewText({ keyword, text })
    const expandedText = getThreadExpandedText({ keyword, sentiments, text })

    setPreviewText(previewText)

    setHighlightedPreviewElement(getHighlightedText(previewText))

    setHighlightedExpandedElement(getHighlightedText(expandedText))
  }, [text, keyword])

  const languageLabel = language
    ? capitalizeFirstLetter(language)
    : originalLangCode

  const shouldShowTranslation =
    languageLabel && originalLangCode !== DEFAULT_ORIGINAL_LANG_CODE

  return (
    <div className={styles.liveNotificationItem}>
      <div className={styles.titleWrapper}>
        {source && (
          <div className={styles.title}>
            <div className={styles.icon}>{icon}</div>
            {capitalizeFirstLetter(source)}
            <a href={link} rel="noreferrer" target="_blank">
              <ExternalLinkSvg className={styles.externalIcon} />
            </a>
            {source === 'Booking' && (
              <div className={styles.score}>{score}.0</div>
            )}
          </div>
        )}
      </div>

      <div className={styles.campaignTitle}>
        {campaign} • {time && moment(time).fromNow()}
        {shouldShowTranslation && (
          <div className={styles.languageLabel}>{languageLabel}</div>
        )}
      </div>

      {text && (
        <div className={styles.text}>
          {text === previewText ? (
            highlightedPreviewElement
          ) : (
            <ClampedParagraph
              isExpanded={isExpanded}
              switchExpandedState={() =>
                setIsExpanded((prevIsExpanded) => !prevIsExpanded)
              }
            >
              {isExpanded
                ? highlightedExpandedElement
                : highlightedPreviewElement}
            </ClampedParagraph>
          )}

          <div className={styles.translations}>
            {shouldShowTranslation && (
              <div
                className={styles.translate}
                onClick={() =>
                  setIsTranslated((prevIsTranslated) => !prevIsTranslated)
                }
              >
                {isTranslated ? en.seeOriginal : en.seeTranslation}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
