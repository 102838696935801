import { useState, FC } from 'react'
import { toast } from 'react-toastify'
import { UpdateSelfParams } from 'src/services/napi/updateSelf/interfaces/updateSelf.interface'
import { updateSelf } from 'src/services/napi/updateSelf'
import en from 'src/constants/en'
import { UpdateSelfPasswordParams } from 'src/services/napi/updateSelfPassword/interfaces/updateSelfPassword.interface'
import { updateSelfPassword } from 'src/services/napi/updateSelfPassword'
import { useGetSelfQuery } from 'src/reactQuery/useGetSelfQuery'
import { BackNavigationButton } from 'src/components/BackNavigationButton'
import { HOME_ROUTE } from 'src/constants/routes'
import YourAccount from './YourAccount'
import styles from './settings.module.scss'

// const marginLeft = ['0%', '20%', '40%', '60%', '80%']
const filterItems = [
  { name: 'Your Account' },
  { name: 'Security' },
  { name: 'Privacy' },
  { name: 'Notifications' },
  { name: 'Additional Resources' },
]

export const Settings: FC = () => {
  const { refetch: refetchUser, data: userData } = useGetSelfQuery()

  const [
    filter,
    // setFilter
  ] = useState<string>(filterItems[0].name)

  // const { data: facebookData, updateData: updateFacebookData } =
  //   useFacebookConnect()

  const handleUpdateUser = async (
    params: UpdateSelfParams
  ): Promise<boolean> => {
    try {
      await updateSelf(params)

      refetchUser()

      toast.success(en.USER_UPDATE_SUCCESS_MESSAGE)

      return true
    } catch (error) {
      toast.error(en.USER_UPDATE_ERROR_MESSAGE)

      return false
    }
  }

  const handleUpdatePassword = async (
    params: UpdateSelfPasswordParams
  ): Promise<boolean> => {
    try {
      await updateSelfPassword(params)

      toast.success(en.USER_PASSWORD_UPDATE_SUCCESS_MESSAGE)

      return true
    } catch (error) {
      toast.error(en.USER_PASSWORD_UPDATE_ERROR_MESSAGE)

      return false
    }
  }

  // const onFacebookConnect = () => {
  //   facebookLogin({
  //     onCompleted: (response) => {
  //       updateFacebookData(response)
  //     },
  //     onError: () => {
  //       toast.error(en.FACEBOOK_LOGIN_ERROR_MESSAGE)
  //     },
  //   })
  // }

  // const onFacebookDisconnect = () => {
  //   facebookLogOut({
  //     onCompleted: () => {
  //       updateFacebookData(null)
  //     },
  //     onError: () => {
  //       toast.error(en.FACEBOOK_LOGOUT_ERROR_MESSAGE)
  //     },
  //   })
  // }

  return (
    <div className={styles.settings}>
      <div className={styles.backButton}>
        <BackNavigationButton title={en.settings} url={HOME_ROUTE} />
      </div>

      {/* Temporarily commented */}
      {/* <div
        className={styles.filtersWrapper}
      >
        <div className={styles.filetrs}>
          <ul
            className={styles.pills}
          >
            {filterItems.map((item, index) => (
              <li
                key={item.name}
                className={classNames(styles.pill, 
                  filter === item.name && styles.pillActive)
                onClick={() => setFilter(item.name)}
              >
                  {item.name}
              </li>
            ))}
          </ul>

          {!editMode && (
            <Button
              onClick={() => setEditMode(true)}
            >
              Edit
            </Button>
          ) }
        </div>
      </div> */}

      {filter === 'Your Account' && userData ? (
        <>
          <YourAccount
            handleUpdatePassword={handleUpdatePassword}
            handleUpdateUser={handleUpdateUser}
            userData={userData.user}
          />

          {/* Temporarily commented until we add Meta athentication */}
          {/* <Card className={styles.socialWrapper}>
            <div className={styles.row}>
              <div className={styles.title}>{en.meta}</div>

              <div className={styles.description}>
                {facebookData ? (
                  <Button onClick={() => onFacebookDisconnect()}>
                    {en.disconnect}
                  </Button>
                ) : (
                  <Button onClick={() => onFacebookConnect()}>
                    {en.connect}
                  </Button>
                )}
              </div>
            </div>
          </Card> */}
        </>
      ) : (
        en.nothingHereYet
      )}
    </div>
  )
}
