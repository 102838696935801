import { useEffect, useState, FC, useRef } from 'react'
import { EChart } from 'src/charts/EChart'
import { kFormatter } from 'src/utils/math'
import en from 'src/constants/en'
import { COLORS } from 'src/constants/colors'
import hexToRgbA from 'src/utils/hexToRgba'
import { useGetInformationTooltipsQuery } from 'src/reactQuery/useGetInformationTooltips'
import { AnalyticsFetchDailyTrendsResponse } from 'src/services/__generated__/api'
import { SectionTitle } from 'src/components/SectionTitle'
import { TitleColor } from 'src/components/SectionTitle/enums/titleColor.enum'
import styles from './dailyTrendsSection.module.scss'

interface DailyTrendsSectionProps {
  chartData?: AnalyticsFetchDailyTrendsResponse
  isLoading?: boolean
}

export const DailyTrendsSection: FC<DailyTrendsSectionProps> = ({
  chartData,
  isLoading,
}) => {
  const { data: informationTooltips } = useGetInformationTooltipsQuery()

  const containerRef = useRef<HTMLDivElement>(null)

  const [options, setOptions] = useState<any>(null)

  useEffect(() => {
    const getOptions = () => {
      const data = {
        backgroundColor: COLORS.malibu,
        grid: {
          height: '220',
          left: 'center',
          right: 'center',
          top: '70',
          width: '92%',
        },
        legend: {
          align: 'left',
          data: [
            {
              icon: 'circle',
              itemStyle: {
                borderColor: COLORS.malibu,
                borderWidth: 5,
                color: COLORS.darkBlue,
              },
              name: en.positive,
            },
            {
              icon: 'circle',
              itemStyle: {
                borderColor: COLORS.malibu,
                borderWidth: 5,
                color: COLORS.burningOrange,
              },
              name: en.negative,
              size: 8,
            },
            {
              icon: 'circle',
              itemStyle: {
                borderColor: COLORS.malibu,
                borderWidth: 5,
                color: hexToRgbA(COLORS.alto, 0.2),
                opacity: 1,
              },
              name: en.total,
            },
          ],
          itemGap: 32,
          left: 0,
          selectedMode: false,
          show: true,
          textStyle: {
            color: '#fff',
          },
          top: 0,
          useHTML: true,
        },
        series: [
          {
            color: '#0A12D6',
            data: chartData?.positives,
            emphasis: {
              focus: 'series',
            },
            itemStyle: {
              borderColor: 'rgba(255, 255, 255, 0.4)',
              borderWidth: 4,
              color: '#0A12D6',
            },
            lineStyle: {
              color: '#0A12D6',
              width: 1,
            },
            name: en.positive,
            showAllSymbol: false,
            showSymbol: true,
            smooth: false,
            symbol: 'circle',
            symbolSize: 10,
            type: 'line',
          },
          {
            data: chartData?.negatives,
            emphasis: {
              focus: 'series',
            },
            itemStyle: {
              borderColor: 'rgba(255, 255, 255, 0.4)',
              borderWidth: 4,
              color: '#FF6833',
            },
            lineStyle: {
              color: '#FF6833',
              width: 1,
            },
            name: en.negative,
            showAllSymbol: false,
            showSymbol: true,
            smooth: false,
            symbol: 'circle',
            symbolSize: 10,
            type: 'line',
          },
          {
            barGap: '-100%',
            barWidth: 24,
            data: chartData?.total,
            itemStyle: {
              borderRadius: [6, 6, 0, 0],
              color: '#D7D7D7',
              opacity: 0.2,
            },
            name: en.total,
            tooltip: {
              position: 'right',
            },
            type: 'bar',
            z: -12,
          },
        ],
        tooltip: {
          axisPointer: {
            lineStyle: {
              color: '#fff',
              opacity: '0.21',
            },
            type: 'line',
          },
          cursor: 'default',
          enterable: true,
          extraCssText: 'user-select: text;',
          show: true,
          showDelay: 250,
          trigger: 'axis',
          triggerOn: 'mousemove',
        },
        xAxis: {
          axisLabel: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: '#fff',
              opacity: '0.1',
            },
          },
          axisPointer: {
            lineStyle: {
              type: 'solid',
            },
            show: true,
            triggerTooltip: false,
          },
          axisTick: {
            show: false,
          },
          boundaryGap: true,
          data: chartData?.dates,
        },
        yAxis: [
          {
            axisLabel: {
              formatter(value: number) {
                return kFormatter(value)
              },
              opacity: '0.47',
            },
            axisLine: {
              lineStyle: {
                color: '#fff',
              },
            },
            position: 'left',
            splitLine: {
              lineStyle: {
                opacity: '0.1',
              },
              show: true,
            },
            type: 'value',
          },
          {
            axisLabel: {
              formatter(value: number) {
                return kFormatter(value)
              },
              opacity: '0.47',
            },
            axisLine: {
              lineStyle: {
                color: '#fff',
              },
            },
            position: 'right',
            splitLine: {
              show: false,
            },
            type: 'value',
          },
        ],
      }

      setOptions(data)
    }

    if (chartData) {
      getOptions()
    }
  }, [chartData])

  function getFormattedDate(date: string | undefined) {
    if (date) {
      const formatter = new Intl.DateTimeFormat([], {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      })
      const [month, , day, , year] = formatter.formatToParts(new Date(date))
      return `${year.value} ${month.value} ${day.value}`
    }
    return ''
  }

  return (
    <div className={styles.dailyTrendsSection}>
      <SectionTitle
        isLoading={isLoading}
        title={en.dailyTrends}
        titleColor={TitleColor.WHITE}
        tooltipContent={informationTooltips?.dailyTrends}
      />

      <div className={styles.chartContainer} ref={containerRef}>
        {options ? (
          <EChart options={options} width={containerRef.current?.offsetWidth} />
        ) : null}
      </div>

      <div className={styles.footer}>
        <span>{getFormattedDate(chartData?.dates?.[0])}</span>

        {!!chartData?.dates?.length && (
          <span>
            {getFormattedDate(chartData?.dates[chartData.dates.length - 1])}
          </span>
        )}
      </div>
    </div>
  )
}
