import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import { AnalyticsApi } from 'src/services/analyticsApi'
import {
  AnalyticsFetchThreadsResponse,
  AnalyticsFetchThreadsSentimentType,
  AnalyticsFetchThreadsVectorType,
} from 'src/services/__generated__/api'
import { UseGetNegativeSentimentAnalysisThreadsQueryParams } from './interfaces/useGetNegativeSentimentAnalysisThreadsQuery.interface'

const getNegativeSentimentAnalysisThreads = async (
  campaign?: string,
  keyword?: string,
  lastDate?: string,
  limit?: number,
  selectedKeywords?: string,
  selectedSources?: string,
  fromDate?: string,
  toDate?: string
): Promise<AnalyticsFetchThreadsResponse | null> => {
  if (!lastDate || !limit) {
    return null
  }

  const { data } = await AnalyticsApi.v1Private.analyticsFetchThreadsList({
    campaign,
    fromDate,
    keyword,
    lastDate,
    limit,
    selectedKeywords,
    selectedSources,
    sentiment: AnalyticsFetchThreadsSentimentType.NEG,
    toDate,
    vector: AnalyticsFetchThreadsVectorType.Desc,
  })

  return data ?? null
}

export const useGetNegativeSentimentAnalysisThreadsQuery = (
  params: UseGetNegativeSentimentAnalysisThreadsQueryParams
) => {
  return useQuery({
    queryFn: () =>
      getNegativeSentimentAnalysisThreads(
        params.campaign,
        params.keyword,
        params.lastDate,
        params.limit,
        params.selectedKeywords,
        params.selectedSources,
        params.fromDate,
        params.toDate
      ),
    queryKey: [
      QueryKey.GET_NEG_SENTIMENT_ANALYSIS_THREADS,
      ...Object.values(params),
    ],
    refetchInterval: Infinity,
  })
}
