import { FC, UIEvent, useState } from 'react'
import en from 'src/constants/en'
import { useGetInformationTooltipsQuery } from 'src/reactQuery/useGetInformationTooltips'
import {
  KeywordFetchSentimentAnalysis,
  AnalyticsFetchSentimentAnalysisResponseData,
  AnalyticsFetchThreadsResponseDataItem,
  AnalyticsFetchThreadsSentimentType,
} from 'src/services/__generated__/api'
import { BaseModal } from 'src/containers/BaseModal'
import { SectionTitle } from 'src/components/SectionTitle'
import styles from './sentimentAnalysesSection.module.scss'
import { SentimentAnalysesChart } from './SentimentAnalysesChart'
import { SentimentAnalysesNotifications } from './SentimentAnalysesNotifications'
import { FilterModalContent } from './FilterModalContent'
import { isAnalyticsFetchSentimentAnalysisResponseData } from './typeGuards/isAnalyticsFetchSentimentAnalysisResponseData'

interface SentimentAnalysesSectionProps {
  allThreadsData?: AnalyticsFetchThreadsResponseDataItem[]
  applyFilters?: (params: {
    selectedKeywords: string[]
    selectedSources: string[]
  }) => void
  chartData?:
    | AnalyticsFetchSentimentAnalysisResponseData
    | KeywordFetchSentimentAnalysis
  handleThreadsScroll?: (
    event: UIEvent,
    sentiment?: AnalyticsFetchThreadsSentimentType
  ) => void
  hasFilters?: boolean
  isLoading?: boolean
  negativeThreadsData?: AnalyticsFetchThreadsResponseDataItem[]
  neutralThreadsData?: AnalyticsFetchThreadsResponseDataItem[]
  positiveThreadsData?: AnalyticsFetchThreadsResponseDataItem[]
}

export const SentimentAnalysesSection: FC<SentimentAnalysesSectionProps> = ({
  chartData,
  allThreadsData,
  negativeThreadsData,
  neutralThreadsData,
  positiveThreadsData,
  handleThreadsScroll,
  isLoading,
  applyFilters,
  hasFilters,
}) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false)

  const { data: informationTooltips } = useGetInformationTooltipsQuery()

  const analyticsData = isAnalyticsFetchSentimentAnalysisResponseData(chartData)
    ? chartData
    : undefined

  const allKeywords = analyticsData?.allKeywords
  const allSources = analyticsData?.allSources
  const selectedKeywords = analyticsData?.selectedKeywords
  const selectedSources = analyticsData?.selectedSources

  return (
    <div className={styles.sentimentAnalysesSection}>
      <SectionTitle
        isLoading={isLoading}
        title={en.sentimentAnalyses}
        tooltipContent={informationTooltips?.sentimentAnalyses}
      />

      <div className={styles.chartWrapper}>
        <SentimentAnalysesChart chartData={chartData}>
          <SentimentAnalysesNotifications
            allThreadsData={allThreadsData}
            handleThreadsScroll={handleThreadsScroll}
            hasFilters={hasFilters}
            negativeThreadsData={negativeThreadsData}
            neutralThreadsData={neutralThreadsData}
            onFilterClick={() => setIsFilterModalOpen(true)}
            positiveThreadsData={positiveThreadsData}
          />
        </SentimentAnalysesChart>
      </div>

      <BaseModal isOpen={!!isFilterModalOpen}>
        <FilterModalContent
          allKeywords={allKeywords}
          allSources={allSources}
          onClose={() => setIsFilterModalOpen(false)}
          onSave={(params) => {
            applyFilters?.(params)

            setIsFilterModalOpen(false)
          }}
          selectedKeywords={selectedKeywords}
          selectedSources={selectedSources}
        />
      </BaseModal>
    </div>
  )
}
